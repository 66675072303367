//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _ from 'lodash';

import PropTypes from '@components/PropTypes';
import Icon      from '@stateless/atomic/Icon';
import IconType  from '@stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

const propTypes = {
    avatar:    PropTypes.string,
    menuItems: PropTypes.array,
    name:      PropTypes.string,
};

const ProfileMenu = ({
    avatar    = null,
    name      = '',
    menuItems = [],
}) => {
    function renderMenuItems() {
        return _.map(menuItems, (item) => {
            const { onClick, text, iconType } = item;

            return (
                <div
                    className={styles.menuItem}
                    onClick={onClick}
                >
                    <Icon iconType={iconType} />
                    {text}
                </div>
            );
        });
    }

    return (
        <div className={styles.profileMenu}>
            <div className={styles.userWrapper}>
                {name}
                <Icon iconType={IconType.chevronDown} />
            </div>
            <div className={styles.avatarWrapper}>
                <img
                    src={avatar}
                    alt={'avatar'}
                />
            </div>
            <div className={styles.menuWrapper}>
                {renderMenuItems()}
            </div>
        </div>
    );
};

ProfileMenu.propTypes = propTypes;

export default ProfileMenu;
