//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes   from '@components/PropTypes';
import ProfileMenu from '@stateless/composed/ProfileMenu';

import styles from './styles.module.scss';

const propTypes = {
    avatar:    PropTypes.string,
    menuItems: PropTypes.array,
    name:      PropTypes.string,
};

const TopBar = ({
    menuItems,
    name,
    avatar,
}) => {
    return (
        <div className={styles.topBar}>
            <div className={styles.topBar}>
                <div className={styles.topBarContent}>
                    <ProfileMenu
                        menuItems={menuItems}
                        name={name}
                        avatar={avatar}
                    />
                </div>
            </div>
        </div>
    );
};

TopBar.propTypes = propTypes;

export default TopBar;
