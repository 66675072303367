//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useRef }    from 'react';
import { useEffect } from 'react';
import { useState }  from 'react';

import I18n                     from 'i18next';
import _                        from 'lodash';
import { useSelector }          from 'react-redux';
import { useDispatch }          from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import BreadCrumbBar                      from '@connected/BreadCrumbBar';
import CalculationSendEmailModal          from '@connected/CalculationSendEmailModal';
import CalculationFields                  from '@constants/CalculationFields';
import ConfigurationValues                from '@constants/ConfigurationValues';
import Formatter                          from '@helper/Formatter';
import InputRenderHelper                  from '@helper/InputRenderHelper';
import useContextTranslator               from '@hooks/ContextTranslator';
import { useCalculation }                 from '@slices/calculation';
import Button                             from '@stateless/atomic/Button';
import Headline                           from '@stateless/atomic/Headline';
import HeadlineType                       from '@stateless/atomic/Headline/HeadlineType';
import IconType                           from '@stateless/atomic/Icon/IconType';
import LabeledInputColor                  from '@stateless/atomic/LabeledInput/LabeledInputColor';
import Separator                          from '@stateless/atomic/Separator';
import Spacer                             from '@stateless/atomic/Spacer';
import CalculationFrameChart              from '@stateless/composed/CalculationFrameChart';
import CalculationNavigationBar           from '@stateless/composed/CalculationNavigationBar';
import CalculationResultWidget            from '@stateless/composed/CalculationResultWidget';
import LabeledWrapper                     from '@stateless/composed/LableledWrapper';
import LayoutScreen                       from '@stateless/composed/LayoutScreen';
import PageTitle                          from '@stateless/composed/PageTitle';
import TextSliderInput                    from '@stateless/composed/TextSliderInput';
import TextSliderType                     from '@stateless/composed/TextSliderInput/TextSliderType';
import { selectCurrentCalculationResult } from '@store/selectors/calculation';
import { selectCurrentCalculationFields } from '@store/selectors/calculation';

import styles from './styles.module.scss';

const CalculationResultScreen = () => {
    const translator                            = useContextTranslator('screens.calculationScreen');
    const wrapperRef                            = useRef(null);
    const [wrapperHeight, setWrapperHeight]     = useState(0);
    const calculationResult                     = useSelector(selectCurrentCalculationResult);
    const calculationFields                     = useSelector(selectCurrentCalculationFields);
    const dispatch                              = useDispatch();
    const calculationActions                    = useCalculation(dispatch);
    const debouncedFetchCalculationResult       = useDebouncedCallback(calculationActions.fetchCalculationResult, 300);
    const [triggerSendMail, setTriggerSendMail] = useState('');

    function onUpdateData(path) {
        return (value) => {
            const changeSet = {};

            _.set(changeSet, path, value);
            calculationActions.updateCalculationData({
                calculation: changeSet,
            });
            debouncedFetchCalculationResult();
        };
    }

    function onSlideValueChanged(field) {
        return (value) => {
            onUpdateData(field)(value);
        };
    }

    const inputRenderer = new InputRenderHelper({
        validations: [],
        data:        calculationResult,
        onUpdateData,
    });

    useEffect(() => {
        calculationActions.fetchCalculationResult();
    }, []);

    useEffect(() => {
        if (wrapperRef.current) {
            setWrapperHeight(wrapperRef.current.clientHeight);
        }
    }, []);

    function renderSpacerRows(number) {
        const style = {
            height: wrapperHeight,
        };

        return _.times(number, (index) => {
            return (
                <div style={style} />
            );
        });
    }

    function onSaveButtonClicked() {
        calculationActions.saveCalculation();
    }

    const taxEffect = _.get(calculationResult, CalculationFields.taxEffectPerMonth);

    function onDownloadButtonClicked() {
        calculationActions.downloadCalculationPDF();
    }

    function onEMailButtonClicked() {
        setTriggerSendMail(_.uniqueId());
    }

    return (
        <>
            <PageTitle
                title={I18n.t('calculation')}
            />
            <LayoutScreen>
                <BreadCrumbBar />
                <div className={styles.calculationResultScreen}>
                    <Headline
                        type={HeadlineType.headline2}
                        title={translator.t('calculationResultTitle')}
                    />
                    <Spacer height={20} />
                    <Headline
                        type={HeadlineType.headline3}
                        title={translator.t('rentPhase')}
                    />
                    <Spacer height={10} />
                    <div className={styles.grid}>
                        <LabeledWrapper
                            label={translator.t('monthlyPayments')}
                            columns={1}
                        >
                            {inputRenderer.renderCurrencyInput({
                                label:       translator.t('interest'),
                                field:       CalculationFields.interestPerMonth,
                                preIconType: IconType.plus,
                                type:        null,
                                disabled:    true,
                            })}
                            {inputRenderer.renderCurrencyInput({
                                label:       translator.t('redemption'),
                                field:       CalculationFields.redemptionPerMonth,
                                preIconType: IconType.plus,
                                type:        null,
                                disabled:    true,
                            })}
                            {inputRenderer.renderCurrencyInput({
                                label:       translator.t('houseManagement'),
                                field:       CalculationFields.propertyManagerCostsPerMonth,
                                preIconType: IconType.plus,
                                type:        null,
                                disabled:    true,
                            })}
                            {inputRenderer.renderCurrencyInput({
                                label:       translator.t('rentManagement'),
                                field:       CalculationFields.unitManagerCostsPerMonth,
                                preIconType: IconType.plus,
                                type:        null,
                                disabled:    true,
                            })}
                            {inputRenderer.renderCurrencyInput({
                                label:       translator.t('maintenance'),
                                field:       CalculationFields.reservesPerMonth,
                                preIconType: IconType.plus,
                                type:        null,
                                disabled:    true,
                            })}
                            {taxEffect < 0 &&
                                inputRenderer.renderCurrencyInput({
                                    label:       translator.t('taxEffect'),
                                    value:       Math.abs(taxEffect),
                                    preIconType: IconType.plus,
                                    type:        null,
                                    disabled:    true,
                                })
                            }
                            <Separator />
                            {inputRenderer.renderCurrencyInput({
                                label:       translator.t('sumPayments'),
                                field:       CalculationFields.expensesSum,
                                preIconType: IconType.equal,
                                color:       LabeledInputColor.abyss,
                                type:        null,
                                highlight:   true,
                                disabled:    true,
                            })}
                        </LabeledWrapper>
                        <LabeledWrapper
                            label={translator.t('monthlyIncome')}
                            columns={1}
                        >
                            {inputRenderer.renderCurrencyInput({
                                label:       translator.t('rent'),
                                field:       CalculationFields.rentIncomePerMonth,
                                preIconType: IconType.plus,
                                type:        null,
                                disabled:    true,
                            })}
                            {taxEffect > 0 &&
                                inputRenderer.renderCurrencyInput({
                                    label:       translator.t('taxEffect'),
                                    value:       Math.abs(taxEffect),
                                    preIconType: IconType.plus,
                                    type:        null,
                                    disabled:    true,
                                })
                            }
                            {renderSpacerRows(4)}
                            <Separator />
                            {inputRenderer.renderCurrencyInput({
                                label:       translator.t('sumIncome'),
                                field:       CalculationFields.incomeSum,
                                preIconType: IconType.equal,
                                color:       LabeledInputColor.green,
                                type:        null,
                                highlight:   true,
                                disabled:    true,
                            })}
                        </LabeledWrapper>
                        <LabeledWrapper
                            label={translator.t('summary')}
                            columns={1}
                        >
                            {inputRenderer.renderCurrencyInput({
                                label:    translator.t('purchasePrice'),
                                field:    CalculationFields.purchasePrice,
                                type:     null,
                                disabled: true,
                            })}
                            {inputRenderer.renderCurrencyInput({
                                label:    translator.t('buySideCosts'),
                                field:    [CalculationFields.additionalPurchasingCostsSource, CalculationFields.total],
                                type:     null,
                                disabled: true,
                            })}
                            {inputRenderer.renderCurrencyInput({
                                label:    translator.t('selfCapital'),
                                field:    CalculationFields.equity,
                                type:     null,
                                disabled: true,
                            })}
                            {inputRenderer.renderCurrencyInput({
                                label:    translator.t('financeSum'),
                                field:    CalculationFields.financingAmount,
                                type:     null,
                                disabled: true,
                            })}
                            {inputRenderer.renderPercentInput({
                                label:    translator.t('rentalYield'),
                                field:    CalculationFields.rentalYield,
                                type:     null,
                                disabled: true,
                            })}
                            {inputRenderer.renderPercentInput({
                                label:    translator.t('ownCapitalYield'),
                                field:    CalculationFields.returnOnEquity,
                                type:     null,
                                disabled: true,
                            })}
                            <Separator visible={false} />
                            {renderSpacerRows(1)}
                        </LabeledWrapper>
                        <div className={styles.labelWrapper}>
                            <CalculationResultWidget />
                            <div className={styles.buttonGroup}>
                                <Button
                                    iconLeft={IconType.save}
                                    onClick={onSaveButtonClicked}
                                />
                                <Button
                                    iconLeft={IconType.download}
                                    onClick={onDownloadButtonClicked}
                                />
                                <Button
                                    iconLeft={IconType.send}
                                    onClick={onEMailButtonClicked}
                                />
                            </div>
                        </div>
                    </div>
                    <Spacer height={40} />
                    <Headline
                        type={HeadlineType.headline3}
                        title={translator.t('raiseAndIntervals')}
                    />
                    <Spacer height={10} />
                    <div className={styles.grid}>
                        <div className={styles.sliderWrapper}>
                            <TextSliderInput
                                minimumValue={ConfigurationValues.increaseInIncome.minimumValue}
                                step={ConfigurationValues.increaseInIncome.step}
                                maximumValue={ConfigurationValues.increaseInIncome.maximumValue}
                                value={_.get(calculationFields, CalculationFields.increaseInIncome)}
                                formatter={Formatter.formatToPercentDigits(1)}
                                onChange={onSlideValueChanged([CalculationFields.calculationFields, CalculationFields.increaseInIncome])}
                                label={translator.t('incomeRaiseInPercent')}
                            />
                            <TextSliderInput
                                minimumValue={ConfigurationValues.rentIncrease.minimumValue}
                                step={ConfigurationValues.rentIncrease.step}
                                maximumValue={ConfigurationValues.rentIncrease.maximumValue}
                                value={_.get(calculationFields, CalculationFields.rentIncrease)}
                                formatter={Formatter.formatToPercentDigits(1)}
                                onChange={onSlideValueChanged([CalculationFields.calculationFields, CalculationFields.rentIncrease])}
                                label={translator.t('rentRaiseInPercent')}
                            />
                            <TextSliderInput
                                minimumValue={ConfigurationValues.increaseInOperatingCosts.minimumValue}
                                step={ConfigurationValues.increaseInOperatingCosts.step}
                                maximumValue={ConfigurationValues.increaseInOperatingCosts.maximumValue}
                                value={_.get(calculationFields, CalculationFields.increaseInOperatingCosts)}
                                formatter={Formatter.formatToPercentDigits(1)}
                                onChange={onSlideValueChanged([CalculationFields.calculationFields, CalculationFields.increaseInOperatingCosts])}
                                label={translator.t('operatingCostsRaiseInPercent')}
                            />
                            <TextSliderInput
                                minimumValue={ConfigurationValues.increaseInValue.minimumValue}
                                step={ConfigurationValues.increaseInValue.step}
                                maximumValue={ConfigurationValues.increaseInValue.maximumValue}
                                value={_.get(calculationFields, CalculationFields.increaseInValue)}
                                formatter={Formatter.formatToPercentDigits(1)}
                                onChange={onSlideValueChanged([CalculationFields.calculationFields, CalculationFields.increaseInValue])}
                                label={translator.t('objectValueRaiseInPercent')}
                            />
                        </div>
                        <div className={styles.sliderWrapper}>
                            <TextSliderInput
                                minimumValue={ConfigurationValues.increaseInIncomeInterval.minimumValue}
                                step={ConfigurationValues.increaseInIncomeInterval.step}
                                maximumValue={ConfigurationValues.increaseInIncomeInterval.maximumValue}
                                value={_.get(calculationFields, CalculationFields.increaseInIncomeInterval)}
                                type={TextSliderType.intervalInYears}
                                onChange={onSlideValueChanged([CalculationFields.calculationFields, CalculationFields.increaseInIncomeInterval])}
                                label={translator.t('incomeRaiseIntervalInYears')}
                                fixedDecimalLength={0}
                            />
                            <TextSliderInput
                                minimumValue={ConfigurationValues.rentIncreaseInterval.minimumValue}
                                step={ConfigurationValues.rentIncreaseInterval.step}
                                maximumValue={ConfigurationValues.rentIncreaseInterval.maximumValue}
                                value={_.get(calculationFields, CalculationFields.rentIncreaseInterval)}
                                type={TextSliderType.intervalInYears}
                                onChange={onSlideValueChanged([CalculationFields.calculationFields, CalculationFields.rentIncreaseInterval])}
                                label={translator.t('rentRaiseIntervalInYears')}
                                fixedDecimalLength={0}
                            />
                            <TextSliderInput
                                minimumValue={ConfigurationValues.increaseInOperatingCostsInterval.minimumValue}
                                step={ConfigurationValues.increaseInOperatingCostsInterval.step}
                                maximumValue={ConfigurationValues.increaseInOperatingCostsInterval.maximumValue}
                                value={_.get(calculationFields, CalculationFields.increaseInOperatingCostsInterval)}
                                type={TextSliderType.intervalInYears}
                                onChange={onSlideValueChanged([CalculationFields.calculationFields, CalculationFields.increaseInOperatingCostsInterval])}
                                label={translator.t('operatingCostsRaiseInterval')}
                                fixedDecimalLength={0}
                            />
                            <TextSliderInput
                                minimumValue={ConfigurationValues.increaseInValueInterval.minimumValue}
                                step={ConfigurationValues.increaseInValueInterval.step}
                                maximumValue={ConfigurationValues.increaseInValueInterval.maximumValue}
                                value={_.get(calculationFields, CalculationFields.increaseInValueInterval)}
                                type={TextSliderType.intervalInYears}
                                onChange={onSlideValueChanged([CalculationFields.calculationFields, CalculationFields.increaseInValueInterval])}
                                label={translator.t('objectValueRaiseInterval')}
                                fixedDecimalLength={0}
                            />
                        </div>
                        <div>
                            <CalculationFrameChart
                                label={translator.t('cashflowDevelopment')}
                                columns={1}
                                chartName={'cashflow-development'}
                            />
                        </div>
                        <div>
                            <CalculationFrameChart
                                label={translator.t('assetAnalysis')}
                                columns={1}
                                chartName={'assets-value'}
                            />
                        </div>
                    </div>
                    <Spacer height={20} />
                    <CalculationNavigationBar />
                    <CalculationSendEmailModal
                        triggerOpen={triggerSendMail}
                    />
                </div>
            </LayoutScreen>
        </>
    );
};

export default CalculationResultScreen;
