//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

// eslint-disable-next-line rulesdir/format-import-linter
import classNames from 'classnames';
import _          from 'lodash';
import Select     from 'react-select';

import PropTypes    from '@components/PropTypes';
import DropDownType from '@stateless/atomic/DropDown/DropDownType';

import styles from './styles.module.scss';

const propTypes = {
    dropDownType:     PropTypes.oneOfObjectValues(DropDownType),
    label:            PropTypes.string,
    noOptionsMessage: PropTypes.string,
    onChange:         PropTypes.func,
    options:          PropTypes.selectOptions,
    pickFirstOption:  PropTypes.bool,
    placeholder:      PropTypes.string,
    value:            PropTypes.any,
};

const DropDown = ({
    dropDownType     = DropDownType.default,
    label            = '',
    noOptionsMessage = '',
    onChange         = _.noop,
    options          = [],
    pickFirstOption  = true,
    placeholder      = '',
    value            = null,
}) => {
    function onBlur() {
        // We need this to be able to scroll inside the list: https://github.com/JedWatson/react-select/issues/1020
        // eslint-disable-next-line no-throw-literal
        throw null;
    }

    function selectedValueChanged(newValue) {
        onChange(_.get(newValue, 'value'));
    }

    function getNoOptionsMessage() {
        return noOptionsMessage;
    }

    function renderLabel() {
        if (
            dropDownType !== DropDownType.whiteWithLabel ||
            !label
        ) {
            return null;
        }

        return (
            <div className={styles.labelContainer}>
                {label}
            </div>
        );
    }

    function findMatchingOption() {
        if (
            _.isNil(value) &&
            pickFirstOption
        ) {
            return _.first(options);
        }

        const iri = _.get(value, 'iri');

        if (iri) {
            return options.find((option) => _.eq(_.get(option, 'value.iri'), iri));
        }

        return options.find((option) => _.eq(_.get(option, 'value'), value));
    }

    return (
        <div
            className={classNames(
                styles.dropdownContainer,
                {
                    [styles.whiteWithLabel]: dropDownType === DropDownType.whiteWithLabel,
                    [styles.white]:          dropDownType === DropDownType.white,
                },
            )}
        >
            {renderLabel()}
            <Select
                className={'wrapper'}
                classNamePrefix="react-select"
                options={options}
                value={findMatchingOption()}
                onChange={selectedValueChanged}
                onBlur={onBlur}
                placeholder={placeholder}
                noOptionsMessage={getNoOptionsMessage}
            />
        </div>
    );
};

DropDown.propTypes = propTypes;

export default DropDown;
