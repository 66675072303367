//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _              from 'lodash';
import { useHistory } from 'react-router';

import Routes               from '@constants/Routes';
import useContextTranslator from '@hooks/ContextTranslator';
import Button               from '@stateless/atomic/Button';
import ButtonType           from '@stateless/atomic/Button/ButtonType';
import Headline             from '@stateless/atomic/Headline';
import HeadlineType         from '@stateless/atomic/Headline/HeadlineType';
import Icon                 from '@stateless/atomic/Icon';
import IconType             from '@stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

const propTypes = {};

const FaqInfoWidget = () => {
    const translator = useContextTranslator('components.faqInfoWidget');
    const history    = useHistory();

    function onToFaqClicked() {
        const faqUrl = translator.t('faqUrl');

        window.open(faqUrl, '_blank');
    }

    function onToGuideClicked() {
        history.push(Routes.guide);
    }

    function renderTexts() {
        const texts = translator.t('texts', {
            returnObjects: true,
        });

        return _.map(texts, (text) => {
            return (
                <p>
                    {text}
                </p>
            );
        });
    }

    return (
        <div className={styles.faqInfoWidget}>
            <div className={styles.textWrapper}>
                <Headline
                    type={HeadlineType.headline3}
                    title={translator.t('title')}
                />
                <Headline
                    type={HeadlineType.headline4}
                    title={translator.t('subTitle')}
                />
                <div>
                    {renderTexts()}
                </div>
                <div className={styles.buttonWrapper}>
                    <Button
                        type={ButtonType.edgy}
                        text={translator.t('toFaq')}
                        iconLeft={IconType.circleArrowRight}
                        onClick={onToFaqClicked}
                    />
                    <Button
                        type={ButtonType.edgy}
                        text={translator.t('toGuide')}
                        iconLeft={IconType.circleArrowRight}
                        onClick={onToGuideClicked}
                    />
                </div>
            </div>
            <div className={styles.iconWrapper}>
                <Icon iconType={IconType.idea} />
            </div>
        </div>
    );
};

FaqInfoWidget.propTypes = propTypes;

export default FaqInfoWidget;
