//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import UserFields from '@constants/UserFields';

export const selectToken = (state) => {
    return _.get(state, ['user', 'token']);
};

export const selectUserIri = (state) => {
    return _.get(state, ['user', 'iri']);
};

export const selectUser = (state) => {
    return _.get(state, ['user', 'user']);
};

export const selectUserContract = (state) => {
    return _.get(selectUser(state), 'contract');
};

export const selectUserFullName = (state) => {
    const user      = _.get(state, ['user', 'user']);
    const firstname = _.get(user, UserFields.firstname);
    const lastname  = _.get(user, UserFields.lastname);

    if (
        !firstname &&
        !lastname
    ) {
        return null;
    }

    return `${firstname} ${lastname}`;
};

export const selectUserId = (state) => {
    return _.get(state, ['user', 'id']);
};

export const selectColorSettings = (state) => {
    return _.get(state, ['user', 'colorSettings']);
};

export default selectToken;
