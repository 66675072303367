//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import IconBreadCrumbEnd    from '@assets/icons/breadcrumb-end.svg';
import IconBreadCrumbStart  from '@assets/icons/breadcrumb-start.svg';
import IconCard             from '@assets/icons/card.svg';
import IconCheck            from '@assets/icons/check.svg';
import IconChevronDown      from '@assets/icons/chevronDown.svg';
import IconChevronRight     from '@assets/icons/chevronRight.svg';
import IconCircleArrowLeft  from '@assets/icons/circleArrowLeft.svg';
import IconCircleArrowRight from '@assets/icons/circleArrowRight.svg';
import IconCircleCheck      from '@assets/icons/circleCheck.svg';
import IconCirclePlus       from '@assets/icons/circlePlus.svg';
import IconClipboard        from '@assets/icons/clipboard.svg';
import IconContract         from '@assets/icons/contract.svg';
import IconCross            from '@assets/icons/cross.svg';
import IconDownload         from '@assets/icons/download.svg';
import IconEllipsis         from '@assets/icons/ellipsis.svg';
import IconEqual            from '@assets/icons/equal.svg';
import IconHome             from '@assets/icons/home.svg';
import IconIdea             from '@assets/icons/idea.svg';
import IconInformation      from '@assets/icons/information.svg';
import IconLogout           from '@assets/icons/logout.svg';
import IconMenu             from '@assets/icons/menu.svg';
import IconPen              from '@assets/icons/pen.svg';
import IconPieChart         from '@assets/icons/pieChart.svg';
import IconPlus             from '@assets/icons/plus.svg';
import IconRocket           from '@assets/icons/rocket.svg';
import IconSave             from '@assets/icons/save.svg';
import IconSend             from '@assets/icons/send.svg';
import IconSettings         from '@assets/icons/settings.svg';
import IconSettingsBig      from '@assets/icons/settingsBig.svg';
import IconTrash            from '@assets/icons/trash.svg';
import IconUser             from '@assets/icons/user.svg';
import IconZoom             from '@assets/icons/zoom.svg';
import IconZoomBox          from '@assets/icons/zoomBox.svg';
import PropTypes            from '@components/PropTypes';

import IconType from './IconType';
import styles   from './styles.module.scss';

const propTypes = {
    iconType: PropTypes.iconType,
};

const Icon = ({
    iconType = null,
}) => {
    function renderSvg() {
        const iconMapping = {
            [IconType.breadCrumbEnd]:    <IconBreadCrumbEnd />,
            [IconType.breadCrumbStart]:  <IconBreadCrumbStart />,
            [IconType.card]:             <IconCard />,
            [IconType.check]:            <IconCheck />,
            [IconType.chevronDown]:      <IconChevronDown />,
            [IconType.chevronRight]:     <IconChevronRight />,
            [IconType.contract]:         <IconContract />,
            [IconType.cross]:            <IconCross />,
            [IconType.pieChart]:         <IconPieChart />,
            [IconType.ellipsis]:         <IconEllipsis />,
            [IconType.home]:             <IconHome />,
            [IconType.rocket]:           <IconRocket />,
            [IconType.clipboard]:        <IconClipboard />,
            [IconType.circleArrowLeft]:  <IconCircleArrowLeft />,
            [IconType.circleArrowRight]: <IconCircleArrowRight />,
            [IconType.trash]:            <IconTrash />,
            [IconType.logout]:           <IconLogout />,
            [IconType.circleCheck]:      <IconCircleCheck />,
            [IconType.circlePlus]:       <IconCirclePlus />,
            [IconType.download]:         <IconDownload />,
            [IconType.equal]:            <IconEqual />,
            [IconType.idea]:             <IconIdea />,
            [IconType.information]:      <IconInformation />,
            [IconType.pen]:              <IconPen />,
            [IconType.menu]:             <IconMenu />,
            [IconType.plus]:             <IconPlus />,
            [IconType.save]:             <IconSave />,
            [IconType.send]:             <IconSend />,
            [IconType.settings]:         <IconSettings />,
            [IconType.settingsBig]:      <IconSettingsBig />,
            [IconType.user]:             <IconUser />,
            [IconType.zoom]:             <IconZoom />,
            [IconType.zoomBox]:          <IconZoomBox />,
        };

        return iconMapping[iconType];
    }

    return (
        <span className={styles.icon}>
            {renderSvg()}
        </span>
    );
};

Icon.propTypes = propTypes;

export default Icon;
