//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useEffect } from 'react';

import I18n                     from 'i18next';
import _                        from 'lodash';
import { useSelector }          from 'react-redux';
import { useDispatch }          from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import BreadCrumbBar            from '@connected/BreadCrumbBar';
import CalculationFields        from '@constants/CalculationFields';
import ConfigurationValues      from '@constants/ConfigurationValues';
import UnitFields               from '@constants/UnitFields';
import InputRenderHelper        from '@helper/InputRenderHelper';
import useContextTranslator     from '@hooks/ContextTranslator';
import { useCalculation }       from '@slices/calculation';
import Headline                 from '@stateless/atomic/Headline';
import HeadlineType             from '@stateless/atomic/Headline/HeadlineType';
import IconType                 from '@stateless/atomic/Icon/IconType';
import LabeledInput             from '@stateless/atomic/LabeledInput';
import LabeledInputColor        from '@stateless/atomic/LabeledInput/LabeledInputColor';
import Separator                from '@stateless/atomic/Separator';
import Spacer                   from '@stateless/atomic/Spacer';
import CalculationFrameChart    from '@stateless/composed/CalculationFrameChart';
import CalculationNavigationBar from '@stateless/composed/CalculationNavigationBar';
import CalculationResultWidget  from '@stateless/composed/CalculationResultWidget';
import LabeledWrapper           from '@stateless/composed/LableledWrapper';
import LayoutScreen             from '@stateless/composed/LayoutScreen';
import PageTitle                from '@stateless/composed/PageTitle';
import TextSliderInput          from '@stateless/composed/TextSliderInput';
import selectCurrentCalculation from '@store/selectors/calculation';

import styles from './styles.module.scss';

const CalculationFinanceScreen = () => {
    const translator                      = useContextTranslator('screens.calculationScreen');
    const calculation                     = useSelector(selectCurrentCalculation);
    const dispatch                        = useDispatch();
    const calculationActions              = useCalculation(dispatch);
    const additionalPurchasingCostsSource = _.get(calculation, [
        CalculationFields.calculationResult,
        CalculationFields.additionalPurchasingCostsSource,
    ]);
    const debouncedFetchCalculationResult = useDebouncedCallback(calculationActions.fetchCalculationResult, 700);

    useEffect(() => {
        calculationActions.fetchCalculationResult();
    }, []);

    function onUpdateData(path) {
        return (value) => {
            const changeSet = {};

            _.set(changeSet, path, value);
            calculationActions.updateCalculationData({
                calculation: changeSet,
            });
            debouncedFetchCalculationResult();
        };
    }

    const inputRenderer = new InputRenderHelper({
        validations: [],
        data:        calculation,
        onUpdateData,
    });

    function renderSpacerRows(number) {
        return _.times(number, () => {
            return (
                <div />
            );
        });
    }

    function onSlideValueChanged(field) {
        return (value) => {
            onUpdateData(field)(value);
        };
    }

    return (
        <>
            <PageTitle
                title={I18n.t('calculation')}
            />
            <LayoutScreen>
                <BreadCrumbBar />
                <div className={styles.calculationFinanceScreen}>
                    <div className={styles.headerNavigationRow}>
                        <Headline
                            type={HeadlineType.headline2}
                            title={translator.t('calculationFinanceTitle')}
                        />
                        <CalculationNavigationBar />
                    </div>
                    <Spacer height={10} />
                    <div className={styles.divider}>
                        <div>
                            <LabeledWrapper
                                label={translator.t('investPhase')}
                                columns={3}
                            >
                                {inputRenderer.renderCurrencyInput({
                                    label:       translator.t('buyingPriceLivingEntity'),
                                    preIconType: IconType.equal,
                                    disabled:    true,
                                    color:       LabeledInputColor.gray,
                                    type:        null,
                                    field:       [CalculationFields.unit, UnitFields.singlePurchasePrice],
                                })}
                                {inputRenderer.renderCurrencyInput({
                                    label:       translator.t('buyingPriceKfz'),
                                    disabled:    true,
                                    preIconType: IconType.equal,
                                    color:       LabeledInputColor.gray,
                                    type:        null,
                                    field:       [CalculationFields.unit, UnitFields.unitCarParkingPurchasePrice],
                                })}
                                {inputRenderer.renderCurrencyInput({
                                    label:       translator.t('sumPurchasePrice'),
                                    disabled:    true,
                                    preIconType: IconType.equal,
                                    color:       LabeledInputColor.green,
                                    type:        null,
                                    field:       [CalculationFields.unit, UnitFields.purchasePriceField],
                                })}
                                <Separator columnSpan={3} />
                                {inputRenderer.renderCurrencyInput({
                                    label:       translator.t('notaryCosts'),
                                    disabled:    true,
                                    preIconType: IconType.plus,
                                    type:        null,
                                    value:       _.get(additionalPurchasingCostsSource, CalculationFields.notaryFeesInEuroCentEuro),
                                })}
                                {inputRenderer.renderCurrencyInput({
                                    label:       translator.t('propertyTransferTax'),
                                    disabled:    true,
                                    preIconType: IconType.plus,
                                    type:        null,
                                    value:       _.get(additionalPurchasingCostsSource, CalculationFields.realEstateTransferInEuro),
                                })}
                                {renderSpacerRows(1)}
                                {inputRenderer.renderCurrencyInput({
                                    label:       translator.t('mediationCosts'),
                                    disabled:    true,
                                    preIconType: IconType.plus,
                                    type:        null,
                                    value:       _.get(additionalPurchasingCostsSource, CalculationFields.mediationCostsInEuro),
                                })}
                                <LabeledInput
                                    disabled={true}
                                    label={translator.t('other')}
                                    value={'-'}
                                    preIconType={IconType.plus}
                                />
                                {inputRenderer.renderCurrencyInput({
                                    label:       translator.t('buyingSideCosts'),
                                    disabled:    true,
                                    preIconType: IconType.plus,
                                    type:        null,
                                    value:       _.get(additionalPurchasingCostsSource, CalculationFields.total),
                                })}
                                <Separator columnSpan={3} />
                                {renderSpacerRows(2)}
                                {inputRenderer.renderCurrencyInput({
                                    label: translator.t('selfCapital'),
                                    field: CalculationFields.equityCapital,
                                })}
                                <Separator columnSpan={3} />
                                {renderSpacerRows(2)}
                                {inputRenderer.renderCurrencyInput({
                                    label:       translator.t('financeSum'),
                                    disabled:    true,
                                    preIconType: IconType.equal,
                                    type:        null,
                                    color:       LabeledInputColor.green,
                                    value:       _.get(calculation, [CalculationFields.calculationResult, CalculationFields.financingAmount]),
                                })}
                            </LabeledWrapper>
                            <div className={styles.gridRow}>
                                <TextSliderInput
                                    minimumValue={ConfigurationValues.interest.minimumValue}
                                    step={ConfigurationValues.interest.step}
                                    maximumValue={ConfigurationValues.interest.maximumValue}
                                    value={_.get(calculation, [CalculationFields.calculationFields, CalculationFields.interest])}
                                    label={translator.t('interest')}
                                    onChange={onSlideValueChanged([CalculationFields.calculationFields, CalculationFields.interest])}

                                />
                                <TextSliderInput
                                    minimumValue={ConfigurationValues.redemption.minimumValue}
                                    step={ConfigurationValues.redemption.step}
                                    maximumValue={ConfigurationValues.redemption.maximumValue}
                                    value={_.get(calculation, [CalculationFields.calculationFields, CalculationFields.redemption])}
                                    label={translator.t('redemption')}
                                    onChange={onSlideValueChanged([CalculationFields.calculationFields, CalculationFields.redemption])}
                                />
                            </div>
                        </div>
                        <div>
                            <CalculationResultWidget />
                            <CalculationFrameChart
                                label={translator.t('financingDevelopment')}
                                heightFactor={0.9}
                                widthFactor={0.9}
                                chartName={'financing-costs'}
                            />
                        </div>
                    </div>
                    <Spacer height={10} />
                    <CalculationNavigationBar />
                </div>
            </LayoutScreen>
        </>
    );
};

export default CalculationFinanceScreen;
