//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import PropTypes from '@components/PropTypes';
import Icon      from '@stateless/atomic/Icon';
import IconType  from '@stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

const propTypes = {
    active:    PropTypes.bool,
    firstItem: PropTypes.bool,
    iconType:  PropTypes.iconType,
    text:      PropTypes.string,
};

const BreadCrumbItem = ({
    firstItem = false,
    iconType  = null,
    text      = '',
    active    = false,
}) => {
    function renderIcon() {
        if (!iconType) {
            return null;
        }

        return (
            <Icon iconType={iconType} />
        );
    }

    function renderBreadCrumbEnd() {
        if (firstItem) {
            return null;
        }

        return (
            <Icon iconType={IconType.breadCrumbEnd} />
        );
    }

    return (
        <div
            className={classNames(
                styles.breadcrumbItem,
                {
                    [styles.active]: active,
                },
            )}
        >
            {renderBreadCrumbEnd()}
            <div className={styles.content}>
                {renderIcon()}
                {text}
            </div>
            <Icon iconType={IconType.breadCrumbStart} />
        </div>
    );
};

BreadCrumbItem.propTypes = propTypes;

export default BreadCrumbItem;
