//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes from '@components/PropTypes';

import styles from './styles.module.scss';

const propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
};

const LabelWidget = ({
    title = '',
    value = '',
}) => {
    return (
        <div className={styles.labelWidget}>
            <div>
                {title}
            </div>
            <div>
                {value}
            </div>
        </div>
    );
};

LabelWidget.propTypes = propTypes;

export default LabelWidget;
