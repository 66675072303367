//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useState }  from 'react';
import { useRef }    from 'react';
import { useEffect } from 'react';

import classNames from 'classnames';

import PropTypes from '@components/PropTypes';
import Icon      from '@stateless/atomic/Icon';
import IconType  from '@stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

const propTypes = {
    menuItems:  PropTypes.array,
    targetData: PropTypes.object,
};

const RowMenu = ({
    menuItems  = [],
    targetData = {},
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const reference           = useRef(null);
    const handleClickOutside  = (event) => {
        if (
            reference.current &&
            !reference.current.contains(event.target)
        ) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    function toggleMenuClicked(event) {
        event.stopPropagation();
        setIsOpen(!isOpen);
    }

    function renderMenuItems() {
        return menuItems.map((item) => {
            function onMenuItemClicked(event) {
                event.stopPropagation();
                item.onClick(targetData);
                setIsOpen(false);
            }

            return (
                <div
                    key={item.id}
                    className={styles.menuItem}
                    onClick={onMenuItemClicked}
                >
                    <Icon iconType={item.icon} />
                    <span>
                        {item.text}
                    </span>
                </div>
            );
        });
    }

    return (
        <div
            className={styles.rowMenu}
            onClick={toggleMenuClicked}
            ref={reference}
        >
            <Icon iconType={IconType.ellipsis} />
            <div
                className={classNames(
                    styles.menu,
                    {
                        [styles.open]: isOpen,
                    },
                )}
            >
                {renderMenuItems()}
            </div>
        </div>
    );
};

RowMenu.propTypes = propTypes;

export default RowMenu;
