//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import { useState } from 'react';

import classNames     from 'classnames';
import { useHistory } from 'react-router';

import LogoLight          from '@assets/images/logo_light.png';
import useNavigationItems from '@hooks/NavigationItems';
import Button             from '@stateless/atomic/Button';
import ButtonType         from '@stateless/atomic/Button/ButtonType';
import Icon               from '@stateless/atomic/Icon';
import IconType           from '@stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

const Sidebar = () => {
    const [contracted, setContracted]                = useState(false);
    const history                                    = useHistory();
    const { navigationItems, bottomNavigationItems } = useNavigationItems(history);

    function onSidebarToggleClicked() {
        setContracted((current) => !current);
    }

    function renderDashboardItems(items) {
        return items.map((item) => {
            const { route, isActive, onClick, iconType, text } = item;

            return (
                <li
                    key={route}
                    className={classNames(
                        styles.sidebarItem,
                        {
                            [styles.active]: isActive,
                        },
                    )}
                    onClick={onClick}
                >
                    <Icon
                        iconType={iconType}
                    />
                    <span>
                        {text}
                    </span>
                </li>
            );
        });
    }

    return (
        <nav
            className={classNames(
                styles.sidebar,
                {
                    [styles.contracted]: contracted,
                },
            )}
        >
            <div className={styles.sidebarHeader}>
                <img
                    src={LogoLight}
                    alt="LogoLight"
                />
                <Button
                    onClick={onSidebarToggleClicked}
                    type={ButtonType.icon}
                    iconLeft={IconType.menu}
                />
            </div>
            <div className={styles.sidebarContent}>
                <ul>
                    {renderDashboardItems(navigationItems)}
                </ul>
                <ul>
                    {renderDashboardItems(bottomNavigationItems)}
                </ul>
            </div>
        </nav>
    );
};

export default Sidebar;
