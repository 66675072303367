//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n from 'i18next';

import styles from './styles.module.scss';

const propTypes = {};

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <div className={styles.footer}>
            <div className={styles.linkWrapper}>
                <a
                    href={I18n.t('impressUrl')}
                    target="_blank"
                    rel="noreferrer"
                >
                    {I18n.t('imprint')}
                </a>
                <a
                    href={I18n.t('privacyUrl')}
                    target="_blank"
                    rel="noreferrer"
                >
                    {I18n.t('privacy')}
                </a>
            </div>
            <div className={styles.copyright}>
                {I18n.t('copyRight', {
                    year: currentYear,
                })}
            </div>
        </div>
    );
};

Footer.propTypes = propTypes;

export default Footer;
