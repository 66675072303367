//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { useState }  from 'react';
import { useRef }    from 'react';
import { useEffect } from 'react';

import classNames     from 'classnames';
import { useHistory } from 'react-router';

import useNavigationItems from '@hooks/NavigationItems';
import Button             from '@stateless/atomic/Button';
import ButtonType         from '@stateless/atomic/Button/ButtonType';
import Icon               from '@stateless/atomic/Icon';
import IconType           from '@stateless/atomic/Icon/IconType';

import styles from './styles.module.scss';

const MobileMenu = () => {
    const [open, setOpen]                            = useState(false);
    const reference                                  = useRef(null);
    const history                                    = useHistory();
    const { navigationItems, bottomNavigationItems } = useNavigationItems(history);
    const handleClickOutside                         = (event) => {
        if (
            reference.current &&
            !reference.current.contains(event.target)
        ) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    function onMobileMenuToggleClicked() {
        setOpen((current) => !current);
    }

    function renderDashboardItems(items) {
        return items.map((item) => {
            const { route, isActive, onClick, iconType, text } = item;

            return (
                <li
                    key={route}
                    className={classNames(
                        styles.mobileMenuItem,
                        {
                            [styles.active]: isActive,
                        },
                    )}
                    onClick={onClick}
                >
                    <Icon
                        iconType={iconType}
                    />
                    <span>
                        {text}
                    </span>
                </li>
            );
        });
    }

    return (
        <nav
            className={classNames(
                styles.mobileMenu,
                {
                    [styles.open]: open,
                },
            )}
            ref={reference}
        >
            <div className={styles.mobileMenuHeader}>
                <Button
                    onClick={onMobileMenuToggleClicked}
                    type={ButtonType.icon}
                    iconLeft={IconType.menu}
                />
            </div>
            <div className={styles.mobileMenuContent}>
                <ul>
                    {renderDashboardItems(navigationItems)}
                </ul>
                <ul>
                    {renderDashboardItems(bottomNavigationItems)}
                </ul>
            </div>
        </nav>
    );
};

export default MobileMenu;
